import AnimatedComponent from './AnimatedComponent';

/* eslint-disable no-underscore-dangle */

class TeaserVideo extends AnimatedComponent {
  constructor(element) {
    super(element);
    this.element = element;
    this.textElement = element.querySelector('.teaser-video__text');
    this.h2Element = element.querySelector('.teaser-video__text h2');
    this.buttonElement = element.querySelector('.teaser-video__text button');
    this.imageElement = element.querySelector('.teaser-video__image');
    this.videoElement = element.querySelector('video');
    const buttonElement = element.querySelector('button');
    this.videoElement.__canplaythrough = false;
    this.videoElement.__shouldPlay = false;

    this.videoElement.addEventListener('play', () => {
      buttonElement.classList.remove('is-loading');
      element.classList.add('is-playing');
    });
    this.videoElement.addEventListener('pause', () => {
      element.classList.remove('is-playing');
    });
    this.videoElement.addEventListener('canplaythrough', () => {
      setTimeout(() => {
        if (this.videoElement.__shouldPlay) {
          this.videoElement.play();
        }
      }, 100);
      this.videoElement.__canplaythrough = true;
    });
    buttonElement.addEventListener('click', () => {
      this.videoElement.__shouldPlay = true;
      if (!this.videoElement.__canplaythrough) {
        this.loadVideo();
        setTimeout(() => {
          if (this.videoElement.paused) {
            buttonElement.classList.add('is-loading');
          }
        }, 500);
      } else {
        this.videoElement.play();
      }
    });
    this.videoElement.addEventListener('click', () => {
      this.videoElement.pause();
    });
  }

  onScroll() {
    this.animateFigure(this.element, this.imageElement);
    this.animateText(this.textElement, this.h2Element, this.buttonElement);
  }

  loadVideo() {
    const sourceElements = this.videoElement.querySelectorAll('source');
    sourceElements.forEach((sourceElement) => {
      if (sourceElement.dataset.media && window.matchMedia(sourceElement.dataset.media).matches) {
        sourceElement.setAttribute('media', sourceElement.dataset.media);
        sourceElement.setAttribute('src', sourceElement.dataset.src);
        sourceElement.removeAttribute('data-media');
        sourceElement.removeAttribute('data-src');
      }
    });
    setTimeout(() => {
      this.videoElement.load();
    }, 60);
  }
}

[...document.querySelectorAll('.teaser-video')].forEach((element) => {
  new TeaserVideo(element);
});
