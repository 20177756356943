function mapRange(number, in_min, in_max, out_min, out_max) {
  return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

function smoothValue(oldValue, newValue, speed = 0.1) {
  return (oldValue + (speed * (newValue - oldValue)));
}

class HeroVideo {
  constructor(element) {
    const heroVideo = this;
    const videoElement = element.querySelector('video');
    const imgElement = element.querySelector('img');
    const h1Element = element.querySelector('h1');

    this.element = element;
    this.h1Element = h1Element;
    this.videoElement = videoElement;
    this.percentVideoTime = 0;
    this.currentTime = 0;
    this.startAnimationPlayed = false;

    function showAndPlay() {
      if (!heroVideo.startAnimationPlayed) {
        element.classList.remove('show-loading-indicator');
        if (videoElement) {
          videoElement.classList.remove('is-hidden');
          videoElement.play();
        } else if (imgElement) {
          setTimeout(() => {
            imgElement.classList.remove('is-hidden');
          }, 500);
          setTimeout(() => {
            heroVideo.showHeadline();
          }, 1000);
        }
      }
    }

    function onTimeupdate() {
      if (videoElement.currentTime >= 1.68 && !heroVideo.startAnimationPlayed) {
        heroVideo.startAnimationPlayed = true;
        videoElement.pause();
        heroVideo.currentTime = videoElement.currentTime;
        heroVideo.showHeadline();
        videoElement.removeEventListener('timeupdate', onTimeupdate);
      }
      // if (document.scrollingElement.scrollTop === 0) {
      // } else {
      //   heroVideo.startAnimationPlayed = true;
      // }
    }

    if (videoElement) {
      videoElement.addEventListener('timeupdate', onTimeupdate);
      videoElement.load();
      videoElement.addEventListener('canplaythrough', showAndPlay);
    } else {
      showAndPlay();
    }

    setTimeout(() => {
      if (!heroVideo.startAnimationPlayed && videoElement && videoElement.paused) {
        element.classList.add('show-loading-indicator');
      }
    }, 1000);
  }

  showHeadline() {
    const { h1Element } = this;
    h1Element.classList.remove('is-hidden');
  }

  init() {
    const { element, showHeadline } = this;
    if (document.querySelector('body').classList.contains('ie')) {
      element.classList.add('no-video');
      setTimeout(() => {
        showHeadline();
      }, 1000);
    } else {
      this.onResize();
      this.onScroll();
      this.animate();
    }
  }

  onResize() {
    const { element } = this;
    const mainInsideElement = document.querySelector('.main .inside');
    this.offsetTop = mainInsideElement.offsetTop;
    this.offsetHeight = element.offsetHeight;
    this.onScroll();
  }

  onScroll() {
    const { offsetTop, offsetHeight } = this;
    // Wo befindet sich die untere Line des Elements relativ zum Viewport: 1 = untere Viewport-Grenze, 0 = obere Viewport-Grenze
    const relativeBottomLine = ((offsetTop + offsetHeight) - document.scrollingElement.scrollTop) / window.innerHeight;
    let percentVideoTime = mapRange(relativeBottomLine, 1, 1.5, 1, 0);
    percentVideoTime = (percentVideoTime > 1) ? 1 : percentVideoTime;
    percentVideoTime = (percentVideoTime < 0) ? 0 : percentVideoTime;
    this.percentVideoTime = percentVideoTime;
  }

  animate() {
    const {
      videoElement, startAnimationPlayed, percentVideoTime, currentTime,
    } = this;
    if (startAnimationPlayed && videoElement) {
      let newCurrentTime = ((videoElement.duration - 1.68) * percentVideoTime) + 1.68;
      newCurrentTime = (newCurrentTime < 0) ? 0 : newCurrentTime;
      newCurrentTime = (newCurrentTime > videoElement.duration) ? videoElement.duration : newCurrentTime;
      this.currentTime = newCurrentTime;
      this.smoothCurrentTime = smoothValue(currentTime, newCurrentTime, 0.2);
      if (currentTime !== this.smoothCurrentTime) {
        videoElement.currentTime = this.smoothCurrentTime;
      }
    }
  }
}

const heroVideoElements = document.querySelectorAll('.hero-video');
[...heroVideoElements].forEach((element) => {
  new HeroVideo(element);
});
