import AnimatedComponent from './AnimatedComponent';

class HeroGridItem extends AnimatedComponent {
  constructor(element) {
    super(element);
    this.element = element;
    this.headlineElement = element.querySelector('.hero-grid__headline');
    this.textElement = element.querySelector('.hero-grid__text');
  }

  onScroll() {
    this.animateText(this.element, this.headlineElement, this.textElement);
  }
}

[...document.querySelectorAll('.hero-grid__item')].forEach((element) => {
  new HeroGridItem(element);
});
