const EasingFunctions = {
  // no easing, no acceleration
  linear(t) { return t; },
  // accelerating from zero velocity
  easeInQuad(t) { return t * t; },
  // decelerating to zero velocity
  easeOutQuad(t) { return t * (2 - t); },
  // acceleration until halfway, then deceleration
  easeInOutQuad(t) { return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t; },
  // accelerating from zero velocity
  easeInCubic(t) { return t * t * t; },
  // decelerating to zero velocity
  easeOutCubic(t) { return (--t) * t * t + 1; },
  // acceleration until halfway, then deceleration
  easeInOutCubic(t) { return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; },
  // accelerating from zero velocity
  easeInQuart(t) { return t * t * t * t; },
  // decelerating to zero velocity
  easeOutQuart(t) { return 1 - (--t) * t * t * t; },
  // acceleration until halfway, then deceleration
  easeInOutQuart(t) { return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t; },
  // accelerating from zero velocity
  easeInQuint(t) { return t * t * t * t * t; },
  // decelerating to zero velocity
  easeOutQuint(t) { return 1 + (--t) * t * t * t * t; },
  // acceleration until halfway, then deceleration
  easeInOutQuint(t) { return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t; },
};

class AnimatedComponent {
  constructor(element) {
    const rootMargin = '0px 0px 32px 0px';
    const threshold = [0, 1];

    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            window.addEventListener('scroll', () => this.onScroll(), {
              passive: true,
            });
            window.addEventListener('resize', () => this.onScroll(), {
              passive: true,
            });
            requestAnimationFrame(() => {
              this.onScroll();
            });
          } else {
            window.removeEventListener('scroll', () => this.onScroll());
            window.removeEventListener('resize', () => this.onScroll());
          }
        });
      }, {
        rootMargin,
        threshold,
      },
    );

    io.observe(element);

    requestAnimationFrame(() => {
      this.onScroll();
    });
    window.addEventListener('load', () => {
      setTimeout(this.onScroll.bind(this), 400);
    });
    setTimeout(this.onScroll.bind(this), 400);
  }

  // eslint-disable-next-line class-methods-use-this
  animateFigure(containerElement, figureElement = false) {
    if (containerElement) {
      const boundingClientRect = containerElement.getBoundingClientRect();
      const absoluteY = boundingClientRect.top - window.innerHeight;
      const tippingPoint = window.innerHeight * -0.5;

      if (absoluteY > tippingPoint) {
        let relativeY = EasingFunctions.easeOutQuad(absoluteY / tippingPoint);
        relativeY = relativeY < 0 ? 0 : relativeY;
        relativeY = relativeY > 1 ? 1 : relativeY;
        const scale = (1 - relativeY) * 0.1 + 1;
        if (figureElement) {
          // eslint-disable-next-line no-param-reassign
          figureElement.style.transform = `scale(${scale})`;
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  animateText(containerElement, headlineElement = false, textElement = false) {
    if (containerElement) {
      const boundingClientRect = containerElement.getBoundingClientRect();
      const absoluteY = boundingClientRect.top - window.innerHeight;
      const tippingPoint = window.innerHeight * -0.5;

      if (absoluteY > tippingPoint) {
        let relativeY = EasingFunctions.easeOutQuad(absoluteY / tippingPoint);
        relativeY = relativeY < 0 ? 0 : relativeY;
        relativeY = relativeY > 1 ? 1 : relativeY;
        if (headlineElement) {
          // eslint-disable-next-line no-param-reassign
          headlineElement.style.transform = `translateY(${relativeY * -50 + 50}px)`;
        }
        if (textElement) {
          // eslint-disable-next-line no-param-reassign
          textElement.style.transform = `translateY(${relativeY * -100 + 100}px)`;
        }
      }
    }
  }
}

export default AnimatedComponent;
