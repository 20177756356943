import AnimatedComponent from './AnimatedComponent';

class HeroItem extends AnimatedComponent {
  constructor(element) {
    super(element);
    this.textContainerElement = element.querySelector('.hero-item__text-container');
    this.headlineElement = element.querySelector('.hero-item__headline');
    this.textElement = element.querySelector('.hero-item__text');
    this.figureImgElement = element.querySelector('.hero-item__figure img');
  }

  onScroll() {
    this.animateFigure(this.textContainerElement, this.figureImgElement);
    this.animateText(this.textContainerElement, this.headlineElement, this.textElement);
  }
}

[...document.querySelectorAll('.hero-item')].forEach((element) => {
  // eslint-disable-next-line no-new
  new HeroItem(element);
});
