const tables = document.getElementsByClassName('ce_table');

const optionsObserver = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
};

function callback(tables, observer) {
  tables.forEach((table) => {
    const tableTarget = table.target;
    tableTarget.setAttribute('data-visible', 'false');
    const tableTargetDataVisible = tableTarget.getAttribute('data-visible');
    if (tableTargetDataVisible !== 'true' && table.isIntersecting && table.intersectionRect.bottom <= table.rootBounds.height) {
      const tableTarget = table.target;
      tableTarget.setAttribute('data-visible', 'true');
    } else {
      const tableTarget = table.target;
      const tableTargetDataVisible = tableTarget.getAttribute('data-visible');
      if (tableTargetDataVisible === 'true') {
        tableTarget.setAttribute('data-visible', 'false');
      }
    }
  });
}

document.addEventListener('touchmove', () => {
  const observer = new IntersectionObserver(callback, optionsObserver);
  [...tables].forEach((table) => observer.observe(table));
});
